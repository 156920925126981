@import url("https://fonts.googleapis.com/css2?family=Staatliches&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,700;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shadows+Into+Light:wght@400&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .btn-waylanders {
    @apply bg-transparent transition ease-in-out duration-500 inline-flex p-4 font-Cin rounded-t-lg border-b-2 border-transparent text-TW-tx-disable hover:text-TW-tx-enable hover:border-TW-tx-enable dark:hover:text-TW-tx-enable;
  }

  .btn-waylanders-active {
    @apply bg-transparent transition ease-in-out duration-500 border-TW-el-active text-TW-el-active inline-flex p-4 font-Cin rounded-t-lg border-b-2;
  }

  .icon-waylanders {
    @apply transition ease-in-out duration-500 text-TW-tx-disable hover:text-TW-tx-enable hover:border-gray-300 dark:hover:text-TW-tx-enable focus:border-TW-el-active focus:text-TW-el-active group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300;
  }

  .subtitle-waylanders {
    @apply text-TW-tx-enable font-Cin text-xl md:text-4xl text-center md:text-left;
  }
  .text-waylanders {
    @apply text-TW-tx-enable font-Fir;
  }

  .btn-graphic {
    @apply transition ease-in-out duration-500 saturate-0 opacity-30 focus:saturate-100 inline-flex p-4 rounded-t-lg border-b-2 border-transparent font-Ral hover:saturate-50 hover:opacity-50;
  }

  .btn-graphic-active {
    @apply transition ease-in-out duration-500 saturate-100 opacity-100 focus:saturate-100 inline-flex p-4 rounded-t-lg border-b-2 border-transparent font-Ral;
  }

  .subtitle-graphic {
    @apply text-DarkGR font-Ral text-4xl;
  }
  .text-graphic {
    @apply text-DarkGR font-Ral;
  }
}

*,
*:after,
*::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.gradient-bg {
  /* background: rgb(25,23,18); */
  position: absolute;
  background: rgb(25, 23, 18);
  background: -moz-linear-gradient(
    0deg,
    rgba(25, 23, 18, 1) 25%,
    rgba(25, 23, 18, 0) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(25, 23, 18, 1) 25%,
    rgba(25, 23, 18, 0) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(25, 23, 18, 1) 25%,
    rgba(25, 23, 18, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#191712",endColorstr="#191712",GradientType=1);
  /* mix-blend-mode: multiply; */
  height: 100vh;
  width: 100%;
  z-index: 10;
}

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 20;
  padding: 0 1rem;
  height: 100vh;
  width: 100%;
}

.main-footer {
  /* position: absolute;
  z-index: 21;
  bottom: 30px;
  left: 50%; */
  /* margin-left: -200px; */
}

.color-6 {
  background: #011f26;
}

/* Efecto de los botones de navegación  */

nav a {
  position: relative;
  display: inline-block;
  margin: 3px 3px;
  outline: none;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
  /* font-size: 1.35em; */
}

nav a:hover,
nav a:focus {
  outline: none;
}

.cl-effect-18 {
  position: relative;
  z-index: 1;
}

.cl-effect-18 a {
  padding: 0 5px;
  color: #025e73;
  font-weight: 700;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;
}

.cl-effect-18 a::before,
.cl-effect-18 a::after {
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  height: 2px;
  margin-top: -1px;
  background: #025e73;
  content: "";
  z-index: -1;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  pointer-events: none;
}

.cl-effect-18 a::before {
  -webkit-transform: translateY(-20px);
  -moz-transform: translateY(-20px);
  transform: translateY(-20px);
}

.cl-effect-18 a::after {
  -webkit-transform: translateY(20px);
  -moz-transform: translateY(20px);
  transform: translateY(20px);
}

.cl-effect-18 a:hover,
.cl-effect-18 a:focus {
  color: #f2668b;
}

.cl-effect-18 a:hover::before,
.cl-effect-18 a:hover::after,
.cl-effect-18 a:focus::before,
.cl-effect-18 a:focus::after {
  opacity: 0.7;
}

.cl-effect-18 a:hover::before,
.cl-effect-18 a:focus::before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}

.cl-effect-18 a:hover::after,
.cl-effect-18 a:focus::after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* Hasta aquí efecto de los botones de navegación */

.slick-next:before {
  content: ">";
  font-family: "Courier New", Courier, monospace;
}

.waylanders-navbar {
  background-color: #191712;
}

.main-title {
  font-size: 2rem;
  /* line-height: 2.25rem; */
  color: #f2668b;
  text-align: center;
}

.carousel {
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.bg-image {
  min-height: 100%;
  min-width: 2048px;
  width: 100%;
  height: auto;
  background-position: center;
  filter: grayscale();
  opacity: 30%;
}

@media (min-width: 480px) {
  nav a {
    margin: 5px 5px;
  }
  .main-title {
    font-size: 2.25rem;
  }
}

@media (min-width: 640px) {
  nav a {
    margin: 8px 8px;
  }
  .main-title {
    font-size: 3rem;
    line-height: 4rem;
  }
  #hero {
    padding: 2rem;
  }
}

@media (min-width: 1280px) {
  .bg-image {
    background-size: cover;
    min-width: 100%;
    height: auto;
  }
}

/* Glitch del isotipo de la página */

#hero {
  font-size: 300%;
  --glitch-duration: 8s;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 2rem;
  position: relative;
  width: 100%;
  height: 100%;
  /* color: #025e73; */
  font-family: "Raleway", sans-serif;
}

#hero > h1 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
#hero > h1::before {
  content: "JORGEPARDO.DEV";
  position: absolute;
  left: 3px;
  color: #025e73;
  opacity: 1;
  z-index: -1;
}
#hero > h1::after {
  content: "JORGEPARDO.DEV";
  position: absolute;
  left: 2px;
  color: #575757;
  opacity: 1;
  z-index: -1;
}

#hero > h1:nth-child(1) {
  animation: glitch1 var(--glitch-duration) infinite steps(1, end);
}
#hero > h1:nth-child(2) {
  animation: glitch2 var(--glitch-duration) infinite steps(1, end);
}
#hero > h1:nth-child(3) {
  animation: glitch3 var(--glitch-duration) infinite steps(1, end);
}
#hero > h1:nth-child(4) {
  animation: glitch4 var(--glitch-duration) infinite steps(1, end);
}
#hero > h1:nth-child(5) {
  animation: glitch5 var(--glitch-duration) infinite steps(1, end);
}

@keyframes glitch1 {
  20% {
    clip-path: polygon(0 0%, 100% 0%, 100% 33%, 0 33%);
    transform: translate(-50%, -50%);
  }
  30% {
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100%, 0);
    transform: translate(-50%, -50%);
  }
  47% {
    transform: translate(-45%, -45%);
  }
  48% {
    transform: translate(-50%, -50%);
  }
  49% {
    transform: translate(-55%, -45%);
  }
  50% {
    transform: translate(-50%, -50%);
  }
}

@keyframes glitch2 {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
    clip-path: polygon(0 40%, 100% 40%, 100% 60%, 0% 60%);
    transform: translate(-50%, -50%);
  }
  21% {
    transform: translate(-46%, -46%);
  }
  22% {
    transform: translate(-54%, -54%);
  }
  30% {
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100%, 0);
    transform: translate(-50%, -50%);
  }
  35% {
    transform: translate(-45%, -55%);
  }
  36% {
    transform: translate(-50%, -50%);
  }
  37% {
    transform: translate(-40%, -45%);
  }
  38% {
    transform: translate(-50%, -50%);
  }
  50% {
    transform: translate(-45%, -55%);
  }
  51% {
    transform: translate(-50%, -60%);
  }
  52% {
    transform: translate(-45%, -50%);
  }
  53% {
    transform: translate(-50%, -50%);
  }
  71% {
    transform: translate(-40%, -60%);
  }
  72% {
    transform: translate(-45%, -50%);
  }
  73% {
    transform: translate(-40%, -50%);
  }
  77% {
    transform: translate(-50%, -50%);
  }
}

@keyframes glitch3 {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
    clip-path: polygon(0 70%, 100% 70%, 100% 100%, 0% 100%);
    transform: translate(-50%, -50%);
  }
  30% {
    transform: translate(-40%, -40%);
  }
  31% {
    transform: translate(-50%, -50%);
  }
  35% {
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100%, 0);
    transform: translate(-50%, -50%);
  }
  45% {
    transform: translate(-60%, -60%);
  }
  46% {
    transform: translate(-50%, -50%);
  }
  47% {
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100%, 0);
    transform: translate(-50%, -50%);
  }
  69% {
    transform: translate(-40%, -40%);
  }
  71% {
    transform: translate(-50%, -50%);
  }
}

@keyframes glitch4 {
  0% {
    opacity: 0;
  }
  22% {
    opacity: 1;
    clip-path: polygon(0 32%, 100% 32%, 100% 41%, 0% 41%);
    transform: translate(-48%, -50%);
  }
  24% {
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100%, 0);
    transform: translate(-50%, -50%);
  }
}

@keyframes glitch5 {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
    clip-path: polygon(0 59%, 100% 59%, 100% 71%, 0% 71%);
    transform: translate(-52%, -50%);
  }
  25% {
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100%, 0);
    transform: translate(-50%, -50%);
  }
}

/* Hasta aquí del isotipo de la página */

.test {
  display: none !important;
}

.xp-title {
  color: rgb(242 102 139);
  font-size: 1.5rem;
  line-height: 2.25rem;
}

.xp-duration {
  /* color: #fff; */
  font-size: 0.8rem;
  padding-left: 0.5rem;
  margin-bottom: 1rem;
}

.xp-company {
  /* color: #fff; */
  font-size: 1rem;
  font-weight: bold;
}

.xp-description {
  /* color: #fff; */
  font-size: 1rem;
  padding-top: 0.25rem;
}

.xp-box {
  color: rgb(75 85 99);
  transition: all 1s ease;
  margin-bottom: 1.5rem;
}
.xp-box:hover {
  color: #fff;
}

.feats-box {
  transition: all 1s ease;
  margin-bottom: 1.5rem;
  opacity: 30%;
}

.feats-title {
  text-align: center;
  color: rgb(242 102 139);
  font-size: 1.5rem;
  line-height: 2.25rem;
}

.feats-description {
  color: #fff;
  text-align: center;
  padding: 0 2rem 0 2rem;
}

.feats-box:hover {
  color: #fff;
  opacity: 100%;
}
.chips-container {
  /* max-width: 50%; */
  display: flex;
  justify-content: space-between;
  list-style: none;
}

.chips {
  padding: 0.5rem 1rem;
  margin: 0.25rem;
  color: rgb(107 114 128);
  border: 2px solid rgb(107 114 128);
  font-size: 0.875rem;
  /* display: flex;
  align-items: center; */
  min-width: fit-content;
  height: 2.5rem;
  border-radius: 9999px;
  display: inline;
  transition: all 1s ease;
}

.profile-titles {
  font-size: 2.5rem;
  color: rgb(242 102 139);
  text-align: center;
}

.education-titles {
  font-size: 1rem;
  color: #fff;
  text-align: center;
  letter-spacing: 15px;
  text-transform: uppercase;
  padding-bottom: 4.5rem;
}

.link-icon {
  width: 16px;
  height: auto;
  margin-right: 0.5rem;
}
